import { getPinterestJSUrl } from 'chairisher/context/script';

let pinterestPromise;

/**
 * @returns {Promise}
 */
function pinterestLoader() {
    if (pinterestPromise) {
        return pinterestLoader;
    }
    pinterestPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = getPinterestJSUrl();
        script.onerror = () => reject(new Error(`failed to load script "${getPinterestJSUrl()}"`));
        // We only care about loading the script - nothing to resolve.
        script.onload = resolve;
        document.head.appendChild(script);
    });

    return pinterestPromise;
}

export default pinterestLoader;
