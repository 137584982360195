import $ from 'jquery';
import ProductModel from 'chairisher/model/product';
import FavoriteProductView from 'chairisher/view/product/favorite';
import GridProductView from 'chairisher/view/product/grid/product';

import { logAmplitudeProductImpressionEvent } from 'chairisher/analytics/product';
import { observeIntersectionForElsOnce } from 'chairisher/view/helper/intersectionobserver';
import { canRenderFolderIcons, isProductIdInFolder } from 'chairisher/context/product';

/**
 * View that allows for the manipulation of a product grid and its child products.
 *
 * @param {Object} settings
 * @param {jQuery} settings.$el The jQuery representation of the ProductGrid in the DOM
 * @param {string=} settings.position Optional position of where the ProductGrid is located
 *
 * @class ProductGridView
 * @constructs ProductGridView
 */
class ProductGridView {
    constructor({ $el, position = null }) {
        /**
         * jQuery instance of the product grid DOM element
         *
         * @type {jQuery}
         */
        this.$el = $el;

        /**
         * Mapping of product id to GridProductView, that represents the products currently in the grid
         *
         * @type {Object.<number, GridProductView>}
         */
        this.productIdToGridProductViewMap = {};

        this.position = position;
    }

    /**
     * Populates this ProductGridView's internal representation of the products that it contains
     *
     * @param {number} productId
     * @param {Object.<number, GridProductData>} gridProductData Mapping of product id to GridProductData that represents
     * the products currently in the grid
     */
    addGridProductView(productId, gridProductData) {
        if (!this.getGridProductView(productId)) {
            this.productIdToGridProductViewMap[productId] = new GridProductView({
                $el: this.$el.find(`.js-product[data-product-id="${productId}"]`),
                data: gridProductData,
            });
        }
    }

    /**
     * @returns {jQuery} The jQuery representation of the product grid DOM element
     */
    getEl() {
        return this.$el;
    }

    /**
     * @param productId
     * @returns {GridProductView}
     */
    getGridProductView(productId) {
        return this.productIdToGridProductViewMap[productId];
    }

    /**
     * Disables the spotlight types buttons on each product in the grid
     */
    disableSpotlightTypeButtons() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.disableSpotlightTypeButtons();
        });
    }

    /**
     * Enables the spotlight types buttons on each product in the grid
     */
    enableSpotlightTypeButtons() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.enableSpotlightTypeButtons();
        });
    }

    /**
     * Removes spotlight type pills from each product on the grid
     */
    removeSpotlightTypePills() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.removeSpotlightTypePills();
        });
    }

    /**
     * Removes the event trigger from each product in the grid
     */
    removeEventTrigger() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.removeEventTrigger();
        });
    }

    /**
     * Removes the folder trigger from each product in the grid
     *
     * @param {boolean=} shouldRemoveImmediately Optional flag indicating the folder trigger should be
     *   removed immediately without waiting for the menu to be closed
     */
    removeFolderTrigger(shouldRemoveImmediately = false) {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.removeFolderTrigger(shouldRemoveImmediately);
        });
    }

    /**
     * Renders the spotlight buttons for all products in the grid
     *
     * @param {string} eventToEmit The event for the event trigger to fire when clicked/tapped
     */
    renderEventTrigger(eventToEmit) {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.renderEventTrigger(eventToEmit);
        });
    }

    /**
     * Renders the Top-N icon for each product in the grid
     */
    renderAddToCollectionSortAction() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.renderAddToCollectionSortAction();
        });
    }

    /**
     * Renders the trigger used to flip the card to allow products to be grouped into folder
     */
    renderFolderTrigger() {
        if (canRenderFolderIcons()) {
            Object.entries(this.productIdToGridProductViewMap).forEach(([productId, gridProductView]) => {
                if (isProductIdInFolder(productId) || FavoriteProductView.isProductIdFavorited(productId)) {
                    gridProductView.renderFolderTrigger();
                } else {
                    gridProductView.removeFolderTrigger();
                }
            });
        }
    }

    /**
     * Renders the spotlight buttons for all products in the grid
     */
    renderSpotlightTypesButtons() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.renderSpotlightTypeButtons();
        });
    }

    /**
     * Renders the spotlight wholesale assortment tag for all products in the grid
     */
    renderSpotlightTypesTag() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.renderSpotlightTypeTag();
        });
    }

    /**
     * Renders the spotlight type pills for all products in the grid
     */
    renderSpotlightTypePills() {
        Object.values(this.productIdToGridProductViewMap).forEach((gridProductView) => {
            gridProductView.renderSpotlightTypePills();
        });
    }

    /**
     * Populates this ProductGridView's internal representation of the products that it contains
     *
     * @param {Object.<number, GridProductData>} productIdToGridProductDataMap Mapping of product id to GridProductData that represents
     * the products currently in the grid
     */
    setGridProductViews(productIdToGridProductDataMap) {
        this.productIdToGridProductViewMap = {};

        Object.entries(productIdToGridProductDataMap).forEach(([productId, gridProductData]) => {
            const $productElement = this.$el.find(`.js-product[data-product-id="${productId}"]`);

            this.productIdToGridProductViewMap[productId] = new GridProductView({
                $el: $productElement,
                data: gridProductData,
            });
        });
    }

    /**
     * Sets the merchandised state (merchandised or not) on products in the grid.
     * If there is a change the merch button is re-rendered.
     *
     * @param {Object.<number, GridProductView>} gridProductData Mapping of product id to GridProductView
     */
    setMerchStates(gridProductData) {
        const productIdsToRender = gridProductData.map((currentGridProductData) => currentGridProductData.id);

        Object.entries(this.productIdToGridProductViewMap).forEach(([productId, gridProductView]) => {
            const wasMerchandised = gridProductView.isMerched();
            gridProductView.setIsMerched(productIdsToRender.includes(productId));

            // only re-render if there was a change
            if (wasMerchandised !== gridProductView.isMerched()) {
                gridProductView.renderAddToCollectionSortAction();
            }
        });
    }

    /**
     * Binds a one-time intersection observer for the product represented by $productElement
     *
     * @param {jQuery} $productElement
     */
    bindProductImpressionObserver($productElement) {
        observeIntersectionForElsOnce($productElement, ({ target }) => {
            const product = new ProductModel(this.getGridProductView(target.dataset.productId).getData());
            logAmplitudeProductImpressionEvent(product, this.position);
        });
    }

    /**
     * Triggers a new event while passing along any other additional data provided
     *
     * @param {string} type The new event's type
     * @param {Object=} data Additional data to be passed along as an argument to the event handler
     */
    trigger(type, data = {}) {
        this.$el.trigger($.Event(`grid:${type}`), data);
    }
}

export default ProductGridView;
