import $ from 'jquery';

import AlerterView from 'chairisher/view/alerter';

import { getSpotlightTypesIconMap } from 'chairisher/context/product';

/**
 *
 * @param settings
 *
 * @class SpotlightTypesView
 * @constructs SpotlightTypesView
 */
const SpotlightTypesView = function (settings) {
    settings = $.extend(
        {
            $el: this._$el,
            data: {},
        },
        settings,
    );

    this._$el = settings.$el;
    this._productData = settings.data;
    this._pendingSpotlightData = [];
    this._bind();
};

/**
 * Enum of actions you can take when sending in spotlight type data
 *
 * @enum
 */
SpotlightTypesView.SpotlightTypeAction = {
    ADD: 1,
    REMOVE: 0,
};

/**
 * Enum of Spotlight Type base codes
 *
 * @enum
 */
SpotlightTypesView.BaseCode = {
    WHOLESALE_ASSORTMENT: 'W',
};

/**
 * The jQuery representation of the spotlight types container
 *
 * @type {jQuery}
 * @private
 */
SpotlightTypesView.prototype._$el = null;

/**
 * Flag indicating if the spotlight type buttons are enabled or not
 *
 * @type {boolean}
 * @private
 */
SpotlightTypesView.prototype._areSpotlightTypeButtonsDisabled = false;

/**
 * Id of the timeout function that executes when queueing up spotlight type data to be sent for toggling
 *
 * @type {number}
 * @private
 */
SpotlightTypesView.prototype._spotlightDataQueueTimeoutId = null;

/**
 * The current product's data to manipulate
 *
 * @type {Array.<string>}
 * @private
 */
SpotlightTypesView.prototype._productData = null;

/**
 * Collection of spotlight data waiting to be sent up to the server for toggling
 *
 * @type {Array.<Object>}
 * @private
 */
SpotlightTypesView.prototype._pendingSpotlightData = null;

/**
 * Id of the timeout function that executes when queueing up spotlight type data to be sent for toggling
 *
 * @type {number}
 * @private
 */
SpotlightTypesView.prototype._spotlightDataQueueTimeoutId = null;

/**
 * Disables the spotlight type buttons for this product
 */
SpotlightTypesView.prototype.disableSpotlightTypeButtons = function () {
    this._getSpotlightTypeButtons().addClass('disabled');
    this._areSpotlightTypeButtonsDisabled = true;
};

/**
 * Enables the spotlight type buttons for this product
 */
SpotlightTypesView.prototype.enableSpotlightTypeButtons = function () {
    this._getSpotlightTypeButtons().removeClass('disabled');
    this._areSpotlightTypeButtonsDisabled = false;
};

/**
 * @returns {jQuery}
 */
SpotlightTypesView.prototype.getEl = function () {
    return this._$el;
};

/**
 * @param {string} value The spotlight type value to check for
 * @returns {boolean} True indicates this product has that spotlight type applied to it; false otherwise
 */
SpotlightTypesView.prototype.hasSpotlightType = function (value) {
    if (this._productData && $.isArray(this._productData.spotlight_types)) {
        for (let i = 0; i < this._productData.spotlight_types.length; i++) {
            if (this._productData.spotlight_types[i].value === value) {
                return true;
            }
        }
    }

    return false;
};

/**
 * @param {string} value The spotlight type value to check for
 * @returns {boolean} True indicates this spotlight type is a button; false otherwise
 */
SpotlightTypesView.prototype.isButton = function (value) {
    return !value.startsWith(SpotlightTypesView.BaseCode.WHOLESALE_ASSORTMENT);
};

/**
 * @param {string} value The spotlight type value to check for
 * @returns {boolean} True indicates this spotlight type is a tag; false otherwise
 */
SpotlightTypesView.prototype.isTag = function (value) {
    return value.startsWith(SpotlightTypesView.BaseCode.WHOLESALE_ASSORTMENT);
};

/**
 * Renders the spotlight type buttons for this product based on the product's data
 */
SpotlightTypesView.prototype.renderSpotlightTypeButtons = function () {
    let $merchButtonContainer = this._$el.find('.js-merch-buttons');
    if (!$merchButtonContainer.length) {
        $merchButtonContainer = $('<div></div>', {
            class: 'merch-buttons js-merch-buttons',
        });
        this._$el.prepend($merchButtonContainer);
    }

    const buttons = [];

    $.each(
        getSpotlightTypesIconMap(),
        $.proxy(function (k, v) {
            if (this.isButton(v.value)) {
                var $btn = $('<span></span>', {
                    'class': [
                        'spotlight-button',
                        'js-spotlight-button',
                        this.hasSpotlightType(v.value) ? 'on' : 'off',
                        this._areSpotlightTypeButtonsDisabled ? 'disabled' : '',
                    ].join(' '),
                    'data-current-value': '0',
                    'data-spotlight-type': v.value,
                }).append(
                    $('<span></span>', { class: `cicon ${v.icon}-stroke off` }),
                    $('<span></span>', { class: `cicon ${v.icon}-fill on` }),
                );
            }

            buttons.push($btn);
        }, this),
    );

    $merchButtonContainer.html(buttons);
};

/**
 * Renders the spotlight wholesale assortment type tag for this product based on the product's data
 */
SpotlightTypesView.prototype.renderSpotlightTypeTag = function () {
    let $merchTagContainer = this._$el.find('.js-merch-tag');
    if (!$merchTagContainer.length) {
        $merchTagContainer = $('<div></div>', {
            class: 'merch-tag js-merch-tag',
        });
        this._$el.prepend($merchTagContainer);
    }

    let $btn;

    $.each(
        getSpotlightTypesIconMap(),
        $.proxy(function (k, v) {
            if (this.isTag(v.value) && this.hasSpotlightType(v.value)) {
                $btn = $('<span></span>', { class: 'spotlight-tag' }).append(
                    $('<span></span>', { class: `cicon ${v.icon}-fill` }),
                );
            }
        }, this),
    );

    $merchTagContainer.html($btn);
};

/**
 * Updates the spotlight type button states based on the action being taken and the provided data
 *
 * @param {Array.<ProductSpotlightTypeData>} spotlightTypeData Collection of spotlight type codes and actions
 *   to use to render button states
 */
SpotlightTypesView.prototype.updateSpotlightButtonStates = function (spotlightTypeData) {
    const { SpotlightTypeAction } = SpotlightTypesView;

    const $buttons = this._getSpotlightTypeButtons();

    for (let i = 0; i < spotlightTypeData.length; i++) {
        const datum = spotlightTypeData[i];
        const spotlightTypeAction = datum.action;

        const $btn = $buttons.filter(`[data-spotlight-type="${datum.code}"]`);

        if (spotlightTypeAction === SpotlightTypeAction.ADD) {
            $btn.addClass('on');
            $btn.removeClass('off');
        } else {
            $btn.removeClass('on');
            $btn.addClass('off');
        }
    }
};

/**
 * Updates the product's spotlight type data based on the data and actions provided.
 *
 * Please note that there is a delay in when the data is sent to the server so data can be batched to account for
 * rapid fire updates that can overwrite each other server side.
 *
 * @param {Array.<ProductSpotlightTypeData>} spotlightTypeData Collection of spotlight type codes and actions
 *   to persist in relation to the product
 * @param {SpotlightTypeAction=} Optional action to apply to the SpotLightTypeData
 */
SpotlightTypesView.prototype.updateSpotlightTypeData = function (spotlightTypeData, opt_spotlightTypeAction) {
    // if there is an existing timeout function, cancel it...
    if (this._spotlightDataQueueTimeoutId) {
        window.clearTimeout(this._spotlightDataQueueTimeoutId);
        this._spotlightDataQueueTimeoutId = null;
    }

    // if the spotlight type code is already in the datastructure, remove it since it's a noop...
    for (let i = 0; i < spotlightTypeData.length; i++) {
        const datum = spotlightTypeData[i];
        const { code } = datum;
        let wasUpdated = false;

        if (opt_spotlightTypeAction) {
            datum.action = opt_spotlightTypeAction;
        }

        for (let j = 0; j < this._pendingSpotlightData.length; j++) {
            if (this._pendingSpotlightData[j].code === code) {
                this._pendingSpotlightData[j] = datum;
                wasUpdated = true;
                break;
            }
        }

        if (!wasUpdated) {
            this._pendingSpotlightData.push(datum);
        }
    }

    this.updateSpotlightButtonStates(spotlightTypeData);

    // set a timeout, save off the timer id, and if there are no interruptions, send the data...
    this._spotlightDataQueueTimeoutId = window.setTimeout(
        $.proxy(function () {
            if (this._pendingSpotlightData.length) {
                this.disableSpotlightTypeButtons();

                $.ajax({
                    contentType: 'application/json',
                    method: 'POST',
                    url: this._productData.spotlight_toggle_url,
                    data: JSON.stringify({
                        spotlight_type: this._pendingSpotlightData,
                    }),
                })
                    .done(
                        $.proxy(function (data) {
                            this._pendingSpotlightData = [];
                            this._productData.spotlight_types = data[this._productData.id].spotlight_types;
                            this._trigger('change', this);
                        }, this),
                    )
                    .fail(
                        $.proxy(function (data) {
                            for (let i = 0; i < this._pendingSpotlightData.length; i++) {
                                const code = this._pendingSpotlightData[i];
                                this._$el
                                    .find(`.js-spotlight-button[data-spotlight-type="${code}"]`)
                                    .toggleClass('on off');
                            }
                            AlerterView.error(data.responseJSON.errors[0].message);
                        }, this),
                    );
            }
        }, this),
        1500,
    ); // 1500ms is just a fudge factor...
};

/**
 * Binds actions to the SpotlightTypesView
 *
 * @private
 */
SpotlightTypesView.prototype._bind = function () {
    this._$el.on(
        'click',
        '.js-spotlight-button',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation(); // do not bubble up to the product link

            const $btn = $(e.currentTarget);

            if (!$btn.hasClass('disabled')) {
                const Actions = SpotlightTypesView.SpotlightTypeAction;
                this.updateSpotlightTypeData([
                    {
                        code: $btn.data('spotlight-type'),
                        action: $btn.hasClass('on') ? Actions.REMOVE : Actions.ADD,
                    },
                ]);
            }
        }, this),
    );
};

/**
 * @returns {jQuery} The collection of spotlight type buttons (if any) for this product
 * @private
 */
SpotlightTypesView.prototype._getSpotlightTypeButtons = function () {
    return this._$el.find('.js-spotlight-button');
};

/**
 * Triggers a new event while passing along any other additional data provided
 *
 * @param {string} type The new event's type
 * @param {Object=} opt_data Additional data to be passed along as an argument to the event handler
 * @private
 */
SpotlightTypesView.prototype._trigger = function (type, opt_data) {
    this._$el.trigger($.Event(type), opt_data);
};

export default SpotlightTypesView;
