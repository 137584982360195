import $ from 'jquery';

import ProfileContext from 'chairisher/context/profile';

import {
    trackContactDealer,
    trackSearchDealerLocation,
    trackVisitsDealerSocialMedia,
    trackVisitsDealerWebsite,
} from 'chairisher/analytics/dealer';
import { getRedirectType } from 'chairisher/analytics/search';
import { getShopGuid } from 'chairisher/context/collection';
import {
    ShopPosition,
    TrackingScreen,
    TrackingSocialMediaPlatform,
    logAmplitudeEvent,
    AmplitudeEventProperty,
} from 'chairisher/util/analytics';

const dealerGuid = getShopGuid(); // Guid of the dealer whose profile is being viewed.

/**
 * Bind analytics for a given page.
 *
 * In contrast to typical analytics singletons, actually bind event handlers so this logic is easily sharable
 * between different profile pages.
 *
 * @param {string} screenName Name of the profile page being viewed (e.g., "shop", "portfolio", etc).
 */
export default function bindProfileAnalytics(screenName) {
    $('.js-contact-info-btn').on('click', (e) => {
        trackContactDealer(e, TrackingScreen.SHOP, dealerGuid, undefined, ShopPosition.CONTACT_BUTTON);
    });

    $('.js-shop-location-link').on('click', (e) => {
        trackSearchDealerLocation(e, TrackingScreen.SHOP, dealerGuid, ShopPosition.LOCATION_LINK);
    });

    $('.js-shop-section-links a[href="#contact"]').on('click', (e) => {
        trackContactDealer(e, TrackingScreen.SHOP, dealerGuid, undefined, ShopPosition.CONTACT_ANCHOR_TAG);
    });

    $('.js-website-action').on('click', () => {
        trackVisitsDealerWebsite(dealerGuid);
    });

    $('.js-social-media-links a:not(.js-website-action)').on('click', (e) => {
        const $link = $(e.currentTarget);
        const platform = TrackingSocialMediaPlatform[$link.attr('title').toUpperCase()];
        trackVisitsDealerSocialMedia(e, platform, TrackingScreen.SHOP, dealerGuid);
    });

    const businessSpecializations = ProfileContext.getBusinessSpecializations();
    const eventObject = {
        guid: dealerGuid,
        [AmplitudeEventProperty.REDIRECT_TYPE]: getRedirectType(),
    };
    if (businessSpecializations.length) {
        eventObject.business_specializations = businessSpecializations.join(',');
    }

    logAmplitudeEvent(screenName, eventObject);
}
