import $ from 'jquery';

import Autocomplete from 'chairisher/component/autocomplete';
import FormFieldComponent from 'chairisher/component/form/field';
import Scroller from 'chairisher/component/scroller';
import SearchController from 'chairisher/controller/search';
import AlerterView from 'chairisher/view/alerter';
import BusinessImageViewer from 'chairisher/view/business/image/viewer';
import TooltipHelper from 'chairisher/view/helper/tooltip';
import SellerContactView from 'chairisher/view/sellercontact';
import NavigationView from 'chairisher/view/navigation';
import SavedSearchForm from 'chairisher/view/savedsearchform';

import bindProfileAnalytics from 'chairisher/analytics/profile';

import { trackEmailDealerSend } from 'chairisher/analytics/dealer';
import { getShopGuid } from 'chairisher/context/collection';
import { getSearchAutocompleteEndpoint } from 'chairisher/context/search';
import { TrackingScreen } from 'chairisher/util/analytics';
import { toggleDescription } from 'chairisher/util/collapse';
import { isTouchDevice } from 'chairisher/util/mediaquery';

/**
 * Controller that binds actions for account shop pages
 */
class ShopController extends SearchController {
    /** @inheritDoc * */
    bindPageActions() {
        super.bindPageActions();

        //
        // Bindings for anchor links
        //

        $('.js-shop-section-links a, .js-contact-info-btn').on('click', (e) => {
            const anchorId = $(e.currentTarget).attr('href');
            if (anchorId[0] === '#') {
                e.preventDefault();
                NavigationView.scrollToElement($(anchorId));
            }
        });

        //
        // Bindings for shops without results
        //

        $('.js-form-saved-search').on(SavedSearchForm.Event.TOGGLE, () => {
            $('.js-follow-text').children().toggleClass('hidden');
        });

        const savedSearchForm = new SavedSearchForm();

        $('.js-follow-create').on('click', (e) => {
            e.preventDefault();

            savedSearchForm.follow();
        });

        const $queryEl = $('.js-layout-not-found .js-autocomplete-query');

        if ($queryEl.length) {
            const autocomplete = new Autocomplete({
                $queryEl,
                dataEndpoint: getSearchAutocompleteEndpoint(),
            });

            autocomplete.getQueryEl().on('autocomplete:selected', (e, item) => {
                window.location.href = item.url;
            });
        }

        //
        // Seller Awards
        //
        const $sellerAwardTooltip = $('.js-seller-award');
        if (isTouchDevice()) {
            $sellerAwardTooltip.on('click', (e) => {
                e.preventDefault();
                $sellerAwardTooltip.tooltip({ placement: TooltipHelper.getPlacement });
                $sellerAwardTooltip.tooltip('toggle');
            });
        } else {
            $sellerAwardTooltip.tooltip({ placement: TooltipHelper.getPlacement });
        }

        //
        // Seller Actions
        //

        // TODO CHAIR-9792: Refactor to use dropdown menu component
        $('.js-menu-seller').on('click', function (e) {
            const $navItem = $(this);

            e.preventDefault();
            e.stopPropagation();

            $navItem.toggleClass('expanded');
            $('.nav-item').not($navItem).removeClass('expanded');

            if ($navItem.hasClass('expanded')) {
                $(document.body).one('click', () => {
                    $navItem.removeClass('expanded');
                });
            }
        });

        //
        // Dealer Contact
        //

        const sellerGuid = getShopGuid();
        const sellerContactView = new SellerContactView();

        $('.js-seller-contact-form').on('submit', function (e) {
            e.preventDefault();

            const $form = $(this);
            $form.find('.js-errors').empty();

            sellerContactView
                .submit($form)
                .done(() => {
                    trackEmailDealerSend(e, TrackingScreen.SHOP, sellerGuid);
                    $form.find('#id_message').val('');
                })
                .fail((jqXHR) => {
                    const { responseJSON } = jqXHR;
                    if ($.isPlainObject(responseJSON) && 'errors' in responseJSON) {
                        const { errors } = responseJSON;
                        for (let i = 0; i < errors.length; i++) {
                            const error = errors[i];
                            $form.find(`.js-form-group.${error.field} .js-errors`).html(
                                FormFieldComponent.marshalMessageList([
                                    {
                                        display: error.message,
                                    },
                                ]),
                            );
                        }
                    } else {
                        AlerterView.error();
                    }
                });
        });

        //
        // Description Toggle
        //

        $('.js-description-toggle').on('click', (e) => {
            toggleDescription(e);
        });

        //
        // Portfolio
        //

        const $newsContainer = $('.js-card-rail-news');
        if ($newsContainer.length) {
            new Scroller({
                container: $newsContainer.get(0),
                containerBody: $newsContainer.find('.js-card-rail-body').get(0),
                children: $newsContainer.find('.js-card-news').get(),
            });
        }

        const $portfolioContainer = $('.js-card-rail-portfolio');
        if ($portfolioContainer.length) {
            new Scroller({
                container: $portfolioContainer.get(0),
                containerBody: $portfolioContainer.find('.js-card-rail-body').get(0),
                children: $portfolioContainer.find('.js-card-image').get(),
            });
        }

        new BusinessImageViewer({
            $cards: $('.js-card-rail-portfolio .js-card-image'),
        });
    }

    /** @inheritDoc * */
    bindScreenAnalytics() {
        bindProfileAnalytics('screen - profile');
    }
}

export default ShopController;
