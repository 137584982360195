import $ from 'jquery';

import KeyUtils from 'chairisher/util/key';
import SocialShare from 'chairisher/view/socialshare';

import { isMaxPhone } from 'chairisher/util/mediaquery';

/**
 *
 * @param {Object} options
 * @param {jQuery} options.$cards Cards viewed in this viewer
 * @param {string=} options.viewerTemplateSelector The selector to use to find the viewer template in the DOM
 * @class BusinessImageViewer
 * @classdesc Object that allows you to view a business image full screen
 * @constructs BusinessImageViewer
 */
const BusinessImageViewer = function (options) {
    options = $.extend(
        {
            $cards: this._$cards,
            viewerTemplateSelector: this._viewerTemplateSelector,
        },
        options,
    );

    this._$cards = options.$cards;
    this._$viewerEl = $($(options.viewerTemplateSelector).html());
    $(document.body).append(this._$viewerEl);

    this._bind();
};

/**
 * Cards to view in this viewer
 *
 * @type {jQuery}
 * @private
 */
BusinessImageViewer.prototype._$cards = null;

/**
 * Element that overlays the screen and displays images
 *
 * @type {jQuery}
 * @private
 */
BusinessImageViewer.prototype._$viewerEl = null;

/**
 * Index of currently selected card
 *
 * @type {number}
 * @private
 */
BusinessImageViewer.prototype._currentIndex = null;

/**
 * URL to the endpoint that supplies data
 *
 * @type {string}
 * @private
 */
BusinessImageViewer.prototype._dataEndpointUrl = null;

/**
 * Namespace to bind events to
 *
 * @type {string}
 * @private
 */
BusinessImageViewer.prototype._eventNamespace = 'businessimageviewer';

/**
 * The URL to show when clicking the next button
 * @type {string|null}
 * @private
 */
BusinessImageViewer.prototype._nextUrl = null;

/**
 * The URL to show when clicking the previous button
 * @type {string|null}
 * @private
 */
BusinessImageViewer.prototype._previousUrl = null;

/**
 * Selector used to fetch the viewer template
 *
 * @type {string}
 * @private
 * @default
 */
BusinessImageViewer.prototype._viewerTemplateSelector = '#js-card-viewer-template';

/**
 * @returns {jQuery} The jQuery element holding the main viewer element
 */
BusinessImageViewer.prototype.getViewerEl = function () {
    return this._$viewerEl;
};

/**
 * Hides the viewer
 */
BusinessImageViewer.prototype.hide = function () {
    this._$viewerEl.addClass('hidden');

    // show live chat
    $('#launcher').css('visibility', 'visible');
};

/**
 * Shows the business image viewer
 *
 * @param {string} dataEndpointUrl The endpoint to hit for the image to show
 * @param {string|null} previousUrl The endpoint to hit for the previous image (if any)
 * @param {string|null} nextUrl The endpoint to hit for the next image (if any)
 */
BusinessImageViewer.prototype.show = function (dataEndpointUrl, previousUrl, nextUrl) {
    this._dataEndpointUrl = dataEndpointUrl;
    this._nextUrl = nextUrl;
    this._previousUrl = previousUrl;

    this._fetchData().done(
        $.proxy(function (data) {
            this._$viewerEl.find('.js-viewer-content').html(data.html);

            this._$nextEl = this._$viewerEl.find('.js-next');
            this._$prevEl = this._$viewerEl.find('.js-previous');

            this.toggleNextAction(this._nextUrl === null);
            this.togglePreviousAction(this._previousUrl === null);

            SocialShare.bind();

            this._$viewerEl.removeClass('hidden');

            const trunatedDescriptionMessage = this._$viewerEl.find('.js-description-condensed').text();
            const fullDescriptionMessage = this._$viewerEl.find('.js-description-full').text();
            if (fullDescriptionMessage.length <= trunatedDescriptionMessage.length || !isMaxPhone()) {
                this._$viewerEl.find('.js-description-full').toggleClass('hidden');
                this._$viewerEl.find('.js-description-condensed').toggleClass('hidden');
                this._$viewerEl.find('.js-description-toggle').toggleClass('hidden');
            }

            // hide live chat
            $('#launcher').css('visibility', 'hidden');
        }, this),
    );
};

/**
 * Toggles the "next" action between being enabled and disabled
 *
 * @param {boolean} shouldDisable
 */
BusinessImageViewer.prototype.toggleNextAction = function (shouldDisable) {
    this._$nextEl.toggleClass('disabled', shouldDisable).prop('disabled', shouldDisable);
};

/**
 * Toggles the "previous" action between being enabled and disabled
 *
 * @param {boolean} shouldDisable
 */
BusinessImageViewer.prototype.togglePreviousAction = function (shouldDisable) {
    this._$prevEl.toggleClass('disabled', shouldDisable).prop('disabled', shouldDisable);
};

/**
 * Binds actions to the viewer element
 * @private
 */
BusinessImageViewer.prototype._bind = function () {
    this._$viewerEl.on('click', '.js-card-link', (e) => {
        e.preventDefault();
        e.stopPropagation();
    });

    this._$viewerEl.on(
        'click',
        '.js-close',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();

            this.hide();
        }, this),
    );

    $(document).on(
        'keyup.viewer',
        $.proxy(function (e) {
            if (e.which === KeyUtils.KeyCodes.Escape) {
                this.hide();
            }
        }, this),
    );

    this._$viewerEl.on(
        'click',
        '.js-next:not(.disabled), .js-previous:not(.disabled)',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();

            const isNext = $(e.currentTarget).hasClass('js-next');
            this._currentIndex += isNext ? 1 : -1;
            const urls = this._getUrlsToShow();

            this.show(urls.url, urls.previousCardUrl, urls.nextCardUrl);
        }, this),
    );

    $('.js-card-link').on(
        'click',
        $.proxy(function (e) {
            e.preventDefault();

            const $link = $(e.currentTarget);
            const $card = $link.closest('.js-card-image');
            this._currentIndex = $card.index(); // position in the grid

            const urls = this._getUrlsToShow();

            this.show(urls.url, urls.previousCardUrl, urls.nextCardUrl);
        }, this),
    );
};

/**
 * @returns {$.Deferred} Ajax request that fetches data to display
 * @private
 */
BusinessImageViewer.prototype._fetchData = function () {
    return $.ajax({
        url: this._dataEndpointUrl,
    });
};

/**
 * @returns {Object}
 */
BusinessImageViewer.prototype._getUrlsToShow = function () {
    const url = this._$cards.eq(this._currentIndex).find('.js-card-link').attr('href');
    let nextCardUrl = null;
    let previousCardUrl = null;

    if (this._currentIndex !== 0) {
        previousCardUrl = this._$cards
            .eq(this._currentIndex - 1)
            .find('.js-card-link')
            .attr('href');
    }

    if (this._currentIndex !== this._$cards.length - 1) {
        nextCardUrl = this._$cards
            .eq(this._currentIndex + 1)
            .find('.js-card-link')
            .attr('href');
    }

    return {
        url,
        nextCardUrl,
        previousCardUrl,
    };
};

export default BusinessImageViewer;
