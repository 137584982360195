import $ from 'jquery';

import AlerterView from 'chairisher/view/alerter';

import { isUnauthorized } from 'chairisher/util/status';
import { getQuickCreateUrl } from 'chairisher/context/auth';

/**
 * @param {Object=} options
 * @param {string=} options.formSelector Selector of the saved search form. Defaults to '#js-form-saved-search'
 * @param {string=} options.buttonContainerSelector Selector of the buttons to hide or show. Defaults to '._buttonSelectorFollow, ._buttonSelectorUnfollow'
 * @param {string=} options.buttonSelectorFollow Selector of the "Follow" button
 * @param {string=} options.buttonSelectorUnfollow Selector of the "Unfollow" button
 * @constructor
 */
const SavedSearchForm = function (options) {
    options = $.extend(
        {
            formSelector: this._formSelector,
            buttonContainerSelector: this._buttonContainerSelector,
            buttonSelectorFollow: this._buttonSelectorFollow,
            buttonSelectorUnfollow: this._buttonSelectorUnfollow,
        },
        options,
    );

    this._formSelector = options.formSelector;
    this._buttonSelectorFollow = options.buttonSelectorFollow;
    this._buttonSelectorUnfollow = options.buttonSelectorUnfollow;
    this._buttonContainerSelector =
        options.buttonContainerSelector || [this._buttonSelectorFollow, this._buttonSelectorUnfollow].join(', ');
};

/**
 * Enum of events fired by the SavedSearchForm
 *
 * @enum
 */
SavedSearchForm.Event = {
    TOGGLE: 'savedsearchform:toggle', // indicates the user has toggled followed or unfollowed a search a search
};

/**
 * Selector for the saved search form
 * @type {string}
 * @private
 */
SavedSearchForm.prototype._formSelector = '.js-form-saved-search';

/**
 * Generic selector used by both the Follow and Unfollow buttons
 * @type {string}
 * @private
 */
SavedSearchForm.prototype._buttonContainerSelector = '';

/**
 * Selector for the Follow button
 * @type {string}
 * @private
 */
SavedSearchForm.prototype._buttonSelectorFollow = '.js-btn-save-search';

/**
 * Selector for the Unfollow button
 * @type {string}
 * @private
 */
SavedSearchForm.prototype._buttonSelectorUnfollow = '.js-btn-remove-search';

/**
 * Follows a search
 * @returns {$.Deferred}
 */
SavedSearchForm.prototype.follow = function () {
    const $form = $(this._formSelector);

    return this._toggleFollow({
        data: $form.serialize(),
        type: $form.attr('method'),
        url: $form.attr('action'),
    }).done(
        $.proxy(function (data) {
            $(this._buttonSelectorUnfollow).attr('href', data.delete_url);
        }, this),
    );
};

/**
 * Unfollows a search
 * @returns {$.Deferred}
 */
SavedSearchForm.prototype.unfollow = function () {
    const $unfollowButton = $(this._buttonSelectorUnfollow);
    return this._toggleFollow({
        url: $unfollowButton.attr('href') || $unfollowButton.data('href'),
    });
};

/**
 * Toggles a search to be either followed or unfollowed, and ensures buttons are disabled, toggled, and reenabled
 * at the appropriate time.
 *
 * @param {Object} ajaxSettings
 * @param {string|Object=} ajaxSettings.data Optional string or object containing data to submit
 * @param {string} ajaxSettings.url URL endpoint to hit
 * @param {string=} ajaxSettings.type Optional HTTP method type. Defaults to 'GET'
 * @returns {$.Deferred}
 * @private
 */
SavedSearchForm.prototype._toggleFollow = function (ajaxSettings) {
    const $buttonContainer = $(this._buttonContainerSelector);
    $buttonContainer.popover('hide');

    return $.ajax(
        $.extend(
            {
                authUrl: this._generateAuthUrl(),
            },
            ajaxSettings,
        ),
    )
        .done(
            $.proxy(function (data) {
                $buttonContainer.toggleClass('hidden');
                AlerterView.success($('<span></span>', { html: data.messages[0].message }));
                $(this._formSelector).trigger(SavedSearchForm.Event.TOGGLE);
            }, this),
        )
        .fail((jqXHR) => {
            if (jqXHR && jqXHR.status && !isUnauthorized(jqXHR.status)) {
                // todo: i18n
                AlerterView.error(
                    'An error occurred while trying to remove your saved search. Please try again later.',
                );
            }
        });
};

/**
 * @returns {string} URL to use in AJAX requests in case the user is not authenticated
 * @private
 */
SavedSearchForm.prototype._generateAuthUrl = function () {
    const products = [];
    const contextProducts = chairisher.context.productsJsonToTrack;
    const variant = $(this._buttonSelectorFollow).data('is-seller-follow') ? 'seller-follow' : 'saved-search';

    if (contextProducts && contextProducts.length >= 4) {
        for (let i = 0; i < 4; i++) {
            products.push(contextProducts[i].id);
        }
    }

    return `${getQuickCreateUrl(variant)}&product-ids=${encodeURIComponent(JSON.stringify(products))}`;
};

export default SavedSearchForm;
