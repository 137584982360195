/**
 * Data structure used to render a card menu
 *
 * @typedef {Object} CardMenuData
 * @property {CardMenuBodyItem} [bodyItem] - Optional CardMenuBodyItem to display
 * @property {CardMenuFooterLink} footerLink - The link to display in the CardMenu's footer
 * @property {string} title - The title of the card
 * @property {Array.<CardMenu>} menus - Collection of CardMenu data
 */

/**
 * Data structure used to render a CardMenuBodyItem
 *
 * @typedef {Object} CardMenuBodyItem
 * @property {string} title - The title of the body item
 * @property {string} visualSizedBaseUrl - Sized base URL for rendering the body item's image
 */

/**
 * Data structure used to render a CardMenuItem
 *
 * @typedef {Object} CardMenu
 * @property {string} title - The title of the CardMenu
 * @property {CardMenuBodyItem} - An image and caption to display in the CardMenu
 * @property {Array<CardMenuItem>} menuItems - Collection of CardMenuItem data
 */

/**
 * Data structure used to render a CardMenuFooterLink
 *
 * @typedef {Object} CardMenuFooterLink
 * @property {string} href - The URL of the link
 * @property {string} text - The text to be displayed
 */

/**
 * Intermediary data structure used to render a CardMenuItem
 *
 * @typedef {Object} FolderObject
 * @property {number} id - The id of the thing being represented Folder
 * @property {string} display_name - The display name of the folder
 * @property {number} num_products - The number of products contained in the folder
 * @property {string} first_image_sized_base_url - The sized base url of the folder's first product
 * @property {string} [append_url] - URL used to add a product to the folder
 * @property {string} [remove_url] - URL used to remove a product from the folder
 */

/**
 * Data structure used to render a CardMenuItem
 *
 * @typedef {Object} CardMenuItem
 * @property {Object} data - The original raw data used to render the CardMenuItem
 * @property {number} id - The id of the thing being represented CardMenuItem
 * @property {boolean} isSelected - Flag indicating if the CardMenuItem is selected as a choice or not
 * @property {string} subtitle - The subtitle of the CardMenuItem
 * @property {string} title - The title of the CardMenuItem
 * @property {string} visualSizedBaseUrl - Sized base URL for rendering the CardMenuItem image
 */
import $ from 'jquery';

import ImageUtils from 'chairisher/util/image';
import CookieUtils from 'chairisher/util/cookie';

import { trackCreateFolder, trackFolderTriggerClick } from 'chairisher/analytics/components/foldercardmenu';
import {
    getFolderCreateUrl,
    getFolderCreateUrlText,
    getFolderMenuItemImageFormats,
    getFolderProductFolderListUrl,
    getFolderProductImageFormats,
    getFolderTitle,
} from 'chairisher/context/collection';
import { isProductIdInFolder, updateFolderedProductId, getFavoriteLookupUrl } from 'chairisher/context/product';
import { isMaxTablet } from 'chairisher/util/mediaquery';

/**
 * @param {FolderObject} folder The folder object to transform
 * @returns {CardMenuItem}
 */
function transformFolderObjectToCardMenuItem(folder) {
    return {
        data: folder,
        id: folder.id,
        title: folder.display_name,
        subtitle: `${folder.num_products} item${folder.num_products !== 1 ? 's' : ''}`,
        visualSizedBaseUrl: folder.first_product_sized_base_url,
        isSelected: !!folder.remove_url,
    };
}

/**
 * Component that adapts collection data to be displayed in a CardMenu
 *
 * @param {Object} data
 * @param {string} data.title
 * @param {Array.<FolderObject>} data.folder_lists
 *
 * @returns {CardMenuData}
 */
function transformMenuObjectsToCardMenuData(data) {
    const $footerHTML = $('<form></form>', {
        action: getFolderCreateUrl(),
        method: 'post',
        target: '_blank',
    }).append([
        $('<input/>', {
            type: 'hidden',
            name: 'product_ids',
            value: data.product.id,
        }),
        $('<input/>', {
            type: 'hidden',
            name: 'csrfmiddlewaretoken',
            value: CookieUtils.getValueFromCookie('csrftoken'),
        }),
        $('<button></button>', {
            class: 'btn btn-primary',
            text: getFolderCreateUrlText(),
            type: 'submit',
        }),
    ]);

    const cardMenuData = {
        footerHtml: $footerHTML[0],
        title: getFolderTitle(),
        menus: (data.folder_lists || []).map((folderList) => ({
            title: folderList.display_name,
            menuItems: (folderList.folders || []).map(transformFolderObjectToCardMenuItem),
        })),
    };

    if (data.product) {
        cardMenuData.bodyItem = {
            visualSizedBaseUrl: data.product.sized_base_url,
            title: data.product.title,
        };
    }

    return cardMenuData;
}

/**
 * @param {jQuery} $menuItem
 * @returns {Object} The raw (untransformed) data representing the given menu item
 */
function getMenuItemRawData($menuItem) {
    return $menuItem.data('rawdata');
}

/**
 * Saves the menu item's raw (untransformed) data on the jQuery object for later use
 *
 * @param {jQuery} $menuItem
 * @param {Object} rawData
 */
function setMenuItemRawData($menuItem, rawData) {
    $menuItem.data('rawdata', rawData);
}

/**
 * Updates a menu item with fresh data
 *
 * @param {jQuery} $menuItem The menu item that should be updated with fresh data
 * @param {CardMenuItem} cardMenuItem The data to update the menu item with
 * @returns {jQuery} The updated menu item
 */
function updateMenuItemEl($menuItem, cardMenuItem) {
    setMenuItemRawData($menuItem, cardMenuItem.data);

    $menuItem.attr('data-id', cardMenuItem.id); // use .attr() so we can use it as a selector
    $menuItem.find('.js-menu-item-title').text(cardMenuItem.title);
    $menuItem.find('.js-menu-item-subtitle').text(cardMenuItem.subtitle);
    $menuItem.find('input[type=checkbox]').prop('checked', cardMenuItem.isSelected);

    if (cardMenuItem.visualSizedBaseUrl) {
        $menuItem
            .find('.js-menu-item-visual')
            .html(
                $(
                    '<img />',
                    ImageUtils.generateSrcsetObj(cardMenuItem.visualSizedBaseUrl, getFolderMenuItemImageFormats()),
                ),
            );
    }

    return $menuItem;
}

/**
 * CSS classes that can be applied when transitioning between open/closed states
 *
 * @enum {string}
 */
const TransitionClasses = {
    APPENDED: 'appended',
    APPENDING: 'appending',
    FLIPPED: 'flipped',
    FLIPPING: 'flipping',
    SLID: 'slid',
    SLIDING: 'sliding',
};

export const FolderCardTransitionAnimations = {
    append: {
        progress: TransitionClasses.APPENDING,
        complete: TransitionClasses.APPENDED,
    },
    flip: {
        progress: TransitionClasses.FLIPPING,
        complete: TransitionClasses.FLIPPED,
    },
    slide: {
        progress: TransitionClasses.SLIDING,
        complete: TransitionClasses.SLID,
    },
};

const cardMenuItemListTemplateSelector = '.js-card-menu-item-list';
const cardMenuItemTemplateSelector = '.js-card-menu-item';
const cardMenuTemplateSelector = '#js-card-menu-template';

/**
 * Component used to render a CardMenu for a product's folders
 *
 * @param {Object} settings
 * @param {CardMenu=} settings.cardMenuData The data used to render the CardMenu
 * @param {Object=} settings.rawData The original data used to render the CardMenu before being transformed into CardMenuData
 * @param {FolderCardTransitionAnimations} settings.animation The animation style for desktop
 * @param {number} settings.productId The productId
 * @param {FolderCardPosition} settings.analyticsTrackingPosition The analytics key
 * @param {jQuery} settings.$appendTo Where to insert the menu
 */
export default class FolderCardMenu {
    constructor({ $appendTo, analyticsTrackingPosition, animation, productId, shouldCloseOnSelection = false }) {
        this.$appendTo = $appendTo;
        this.analyticsTrackingPosition = analyticsTrackingPosition;
        this.animation = animation;
        this.productId = productId;
        this.shouldCloseOnSelection = shouldCloseOnSelection;

        this.data = null;
        this.mostRecentXhr = null;

        /**
         * The folder UI. This is opened when the user clicks on the trigger (a folder icon).
         */
        this.$menuEl = null;

        /**
         * The folder icon that triggers the folder UI to open.
         */
        this.$triggerEl = null;
    }

    /**
     * Makes a request to the server to set the folder data for this product.
     *
     * @return {$.Deferred}
     */
    #setData() {
        return this.data
            ? $.Deferred().resolve()
            : $.ajax({
                  url: getFolderProductFolderListUrl(this.productId),
              }).done((data) => {
                  this.data = transformMenuObjectsToCardMenuData(data);
              });
    }

    /**
     * Replaces a row of data in the menu's internal data structure with new data and updates the ui
     *
     * @param {CardMenuItem} menuItemData The new data
     */
    #updateMenuItemData(menuItemData) {
        this.data.menus = this.data.menus.map((menu) => ({
            ...menu,
            menuItems: menu.menuItems.map((menuItem) => (menuItem.id === menuItemData.id ? menuItemData : menuItem)),
        }));
        updateMenuItemEl(this.$menuEl.find(`[data-id=${menuItemData.id}]`), menuItemData);
    }

    /**
     * Adds/removes the product from the folder.
     *
     * @param {FolderObject} folderData
     */
    #toggleProduct(folderData) {
        const url = folderData.append_url || folderData.remove_url;

        if (url && !this.mostRecentXhr) {
            const $checkbox = this.$menuEl.find(`[data-id=${folderData.id}] input[type=checkbox]`);
            $checkbox.prop('checked', url === folderData.append_url);

            updateFolderedProductId(this.productId, $checkbox.prop('checked'));

            this.mostRecentXhr = $.ajax({
                method: 'PATCH',
                url,
            })
                .done(({ folders: [newFolderData] }) => {
                    this.#updateMenuItemData(transformFolderObjectToCardMenuItem(newFolderData));

                    $.ajax({
                        url: getFavoriteLookupUrl(),
                        data: { product_ids: this.productId },
                    }).done((updatedFolderData) => {
                        const isInFolder = updatedFolderData.action_info[0]?.is_in_folder;
                        if (isInFolder !== undefined) {
                            this.$triggerEl.toggleClass('cicon-folder-fill', isInFolder);
                            this.$triggerEl.toggleClass('cicon-folder-stroke', !isInFolder);
                        }
                    });
                })
                .always(() => {
                    this.mostRecentXhr = null;
                });
        }
    }

    #marshalMenu() {
        const $template = $($(cardMenuTemplateSelector).html());
        const $menuListTemplate = $template.find(cardMenuItemListTemplateSelector).remove();
        const $menuItemTemplate = $menuListTemplate.find(cardMenuItemTemplateSelector).remove();

        $template.find('.js-card-title').text(this.data.title);

        this.data.menus.forEach((menu) => {
            if (menu.menuItems.length) {
                const $folderListTitle = $('<span></span>', {
                    class: 'card-menu-item-list-title',
                    text: menu.title,
                });

                const menuItems = menu.menuItems.map((menuItem) =>
                    updateMenuItemEl($menuItemTemplate.clone(), menuItem),
                );

                const $menuListClone = $menuListTemplate.clone();
                $menuListClone.append(menuItems);

                $template.find('.js-card-body').append([$folderListTitle, $menuListClone]);
            }
        });

        if (this.data.bodyItem) {
            const $img = $(
                '<img />',
                ImageUtils.generateSrcsetObj(this.data.bodyItem.visualSizedBaseUrl, getFolderProductImageFormats()),
            );

            $template.find('.js-card-body-item-visual').html($img);
            $template.find('.js-card-body-item-title').text(this.data.bodyItem.title);
        }

        if (this.data.footerLink) {
            $template.find('.js-card-footer').html(
                $('<a></a>', {
                    href: this.data.footerLink.href,
                    text: this.data.footerLink.text,
                }),
            );
        } else if (this.data.footerHtml) {
            $template.find('.js-card-footer').html(this.data.footerHtml);
        }

        this.$menuEl = $template;
    }

    #openMenu() {
        const $deferred = $.Deferred();
        const animation = this.#getAnimation();

        const $el = (this.$appendTo || this.$menuEl).not(`.${animation.complete}`).not(`.${animation.progress}`);

        if ($el.length) {
            $el.addClass([animation.complete, animation.progress].join(' '));
            window.setTimeout(() => {
                $el.removeClass(animation.progress);
                $deferred.resolve();
            }, this.#getTransitionSpeed(animation.complete));
        } else {
            $deferred.resolve();
        }

        return $deferred;
    }

    #bindMenu() {
        this.$menuEl
            .on('click', '.js-action-close', () => {
                this.close();
            })
            .on('submit', '.js-card-footer form', (submitEvent) => {
                trackCreateFolder($(submitEvent.currentTarget), this.analyticsTrackingPosition);
            });

        this.$menuEl.on('click', (e) => {
            e.stopPropagation();
        });

        this.$menuEl.on('click', '.js-action-close', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.close();
        });

        this.$menuEl.on('click', '.js-card-menu-item', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $menuItem = $(e.currentTarget);
            this.#toggleProduct(getMenuItemRawData($menuItem));
            if (this.shouldCloseOnSelection) {
                this.close();
            }
        });
        $(document).one('click', () => {
            this.close();
        });
    }

    #getAnimation() {
        if (isMaxTablet()) {
            return FolderCardTransitionAnimations.slide;
        }
        return this.animation;
    }

    /**
     * @param transitionCompleteClass
     * @returns {number}
     */
    #getTransitionSpeed(transitionCompleteClass) {
        return this.$menuEl && transitionCompleteClass !== TransitionClasses.APPENDED
            ? this.$menuEl.data('transitionms') || 1300
            : 0;
    }

    bind() {
        this.$triggerEl.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            trackFolderTriggerClick(e, this.analyticsTrackingPosition);

            if (this.$menuEl) {
                this.close();
            } else {
                this.#setData().done(() => {
                    this.#marshalMenu();
                    if (this.$appendTo) {
                        this.$menuEl.appendTo(this.$appendTo);
                    } else {
                        this.$menuEl.insertBefore(this.$triggerEl);
                    }
                    this.#bindMenu();
                    this.#openMenu();
                });
            }
        });
    }

    close() {
        const $deferred = $.Deferred();
        const animation = this.#getAnimation();
        const $el = (this.$appendTo || this.$menuEl).not(`.${animation.progress}`);

        if ($el.length > 0) {
            $el.removeClass(animation.complete).addClass(animation.progress);
            window.setTimeout(() => {
                $el.removeClass(animation.progress);
                if (this.$appendTo) {
                    this.$appendTo.removeClass(animation.complete);
                }
                if (this.$menuEl) {
                    this.$menuEl.remove();
                    this.$menuEl = null;
                }
                $deferred.resolve();
            }, this.#getTransitionSpeed(animation.complete));
        } else {
            $deferred.resolve();
        }

        return $deferred;
    }

    marshalFolderTrigger() {
        if (!this.$triggerEl) {
            const isInFolder = isProductIdInFolder(this.productId);
            const iconClass = isInFolder ? 'cicon-folder-fill' : 'cicon-folder-stroke';
            this.$triggerEl = $('<span></span>', {
                class: `cicon folder-trigger js-folder-trigger ${iconClass}`,
            });
        }
        return this.$triggerEl;
    }

    removeFolderTrigger() {
        if (this.$triggerEl) {
            this.$triggerEl.remove();
            this.$triggerEl = null;
        }
    }

    hasFolderTrigger() {
        return !!this.$triggerEl;
    }
}
