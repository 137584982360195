import $ from 'jquery';

import { getCollectionId, getGridProductIds } from 'chairisher/context/collection';
import { getPromotedListingsUrl } from 'chairisher/context/promotedlistings';
import { CollectionPosition } from 'chairisher/util/analytics';
import { observeIntersectionForElsOnce } from 'chairisher/view/helper/intersectionobserver';
import {
    HOUSE_AD_CLASS,
    PROMOTED_LISTING_CLASS,
    registerProductImpression,
} from 'chairisher/view/helper/promotedlistings';
import { updateProductObjectArray } from 'chairisher/context/product';

/**
 * Asks the server for new promoted listings and displayed them on the page.
 *
 * @param {string} gridProductSelector Selector used to select the products in a grid
 * @param {number} numRequested The number of promoted listings requested
 * @returns {$.Deferred}
 */
export default function updatePromotedListings(gridProductSelector, numRequested) {
    const $deferredResponse = $.Deferred();

    const urlParams = new URLSearchParams(window.location.search);
    // Add extra data to params from URL
    Object.entries({
        collection_id: getCollectionId(),
        num_requested: numRequested,
        existing_product_ids: JSON.stringify(getGridProductIds()),
    }).forEach(([key, val]) => {
        if (val) {
            urlParams.set(key, val);
        }
    });

    $.ajax({
        dataType: 'json',
        url: `${getPromotedListingsUrl()}?${urlParams}`,
    }).done((data) => {
        const promotedListingProductIds = data.promoted_listing_product_ids || [];

        updateProductObjectArray(data.products);

        const productsHtml = data.products_html || [];
        const houseAdProductIds = data.house_ad_product_ids || [];
        const promotedListingsProductJson = data.products || [];

        const $gridProducts = $(gridProductSelector);
        const productHtmls = [];

        // decorate each grid product with various pieces of data before inserting in the DOM
        $.each(productsHtml, (i, el) => {
            const $el = $(el);
            const productId = $el.data('product-id');

            if (promotedListingProductIds.includes(productId)) {
                $el.addClass(PROMOTED_LISTING_CLASS);
            } else {
                $el.addClass(HOUSE_AD_CLASS);
            }

            productHtmls.push($el);
        });

        // insert into DOM
        let num = 1;
        const idealNumPerRow = 3;
        const startRow = 3;
        const rowsToSkip = 5;
        const toInsert = productHtmls.concat([]); // create a copy of the array

        const firstPosition = (startRow - 1) * idealNumPerRow - 1;

        // insert the 1st set in the 3rd row
        let $elsToInsert = toInsert.splice(0, idealNumPerRow);
        $gridProducts.eq(firstPosition).after($elsToInsert);

        while (toInsert.length) {
            const insertPosition = idealNumPerRow * num * rowsToSkip;
            $elsToInsert = toInsert.splice(0, idealNumPerRow);
            $gridProducts.eq(firstPosition + insertPosition).after($elsToInsert);

            num += 1;
            if (num > 48) {
                break;
            }
        }

        $.each(productHtmls, (i, $el) => {
            // log impression analytics events when the promoted listings enter the viewport
            observeIntersectionForElsOnce($el, () => {
                registerProductImpression($el, CollectionPosition.MAIN_PRODUCT_GRID);
            });
        });

        $deferredResponse.resolve({
            responseData: data,
            houseAdProductIds,
            promotedListingsProductJson,
        });
    });

    return $deferredResponse;
}
