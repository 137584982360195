import $ from 'jquery';

import AlerterView from 'chairisher/view/alerter';
import ProductModel from 'chairisher/model/product';

import pinterestLoader from 'chairisher/thirdparty/pinterest';

import { TrackingSocialMediaPlatform } from 'chairisher/util/analytics';
import {
    logSocialShareAbandonEvent,
    logSocialShareErrorEvent,
    logSocialShareEvent,
} from 'chairisher/analytics/product';
import { isDesktopUserAgent } from 'chairisher/util/mediaquery';

pinterestLoader().catch((e) => {
    // in case pinterest doesn't load, remove it as a sharing option
    $('.js-pinterest-share-container').remove();
    throw e;
});

const SocialShare = {
    /**
     * Binds social share buttons and links
     */
    bind() {
        const $shareBtn = $('.js-share-btn');
        const product = new ProductModel({ id: $shareBtn.data('product-id') });
        const shareUrl = $shareBtn.data('url');

        // EMAIL
        $('.js-email-share').on('click', (e) => {
            e.preventDefault();
            const $btn = $(e.currentTarget);

            window.location.href = `mailto:?subject=${encodeURIComponent(
                $btn.data('subject'),
            )}&body=${encodeURIComponent(shareUrl)}`;

            logSocialShareEvent(product, TrackingSocialMediaPlatform.EMAIL, shareUrl);
        });

        // PINTEREST
        $('.js-pinterest-share').on('click', () => {
            logSocialShareEvent(product, TrackingSocialMediaPlatform.PINTEREST, shareUrl);
        });

        // COPY & SHARE
        if (navigator.share && !isDesktopUserAgent()) {
            $shareBtn.on('click', (e) => {
                const $btn = $(e.currentTarget);

                navigator
                    .share({
                        title: $btn.data('title'),
                        url: shareUrl,
                    })
                    .then(
                        () => {
                            logSocialShareEvent(product, TrackingSocialMediaPlatform.DEVICE_SHARE, shareUrl);
                        },
                        () => {
                            logSocialShareAbandonEvent(product, TrackingSocialMediaPlatform.DEVICE_SHARE, shareUrl);
                        },
                    );
            });
        } else {
            const $socialMenu = $('.js-social-menu');
            $shareBtn.on('mouseenter', () => $socialMenu.removeClass('hidden'));
            $('.js-copy-share').on('click', (e) => {
                const $btn = $(e.currentTarget);
                const $btnText = $btn.find('.js-copy-btn-text');

                navigator.clipboard.writeText(shareUrl).then(
                    () => {
                        $btnText.text($btn.data('clicked-text'));

                        setTimeout(() => {
                            $socialMenu.addClass('hidden');
                            $btnText.text($btn.data('text'));
                        }, 1000);

                        logSocialShareEvent(product, TrackingSocialMediaPlatform.LINK_COPY, shareUrl);
                    },
                    () => {
                        AlerterView.error();
                        logSocialShareErrorEvent(product, TrackingSocialMediaPlatform.LINK_COPY, shareUrl);
                    },
                );
            });
        }
    },
};

export default SocialShare;
